import Description from "./components/Description";
import "./App.css";
import Nav from "./components/Nav";
import Request from "./components/Request";

function App() {
  const codeSnippet = `REQUEST (POST)
    $ curl https://api.laptopreturn.com/order
    -H "api_key": "YOUR_KEY"
    --data {
      "user_email": "rsmith@microsoft.com",
      "employee_name": "Shelly Sample",
      "employee_email": "shelly.sample@gmail.com",
      "employee_phone": "585-200-6265",
      "employee_street1": "4059 Mt Lee Dr.",
      "employee_street2": "Unit #2",
      "employee_city": "Hollywood",
      "employee_state": "CA",
      "employee_zip": "90068",
      "fd_department": "IT Dept", //Final Destination Department or Person's Name
      "fd_street_1": "350 Fifth Avenue",
      "fd_street_2": "12th Floor",
      "fd_city": "New York",
      "fd_state": "NY",
      "fd_zip": "10118"
  }`;
  const codeSnippet2 = `{ 
    message: "Order Created Successfully" 
}`;
  const codeSnippet3 = `{ 
    message: "Can Not Create Order" 
}`;
  const codeSnippet4 = `{ 
    message: "Invalid User Email"
}`;
  const codeSnippet5 = `{ 
    message: "Invalid Api Key" 
}`;

  return (
    <div className="h-full">
      <Nav />
      <div className="p-5 pt-0 w-full flex md:flex-row md:justify-between md:items-start flex-col">
        <div className="md:w-[65%] w-full">
          <Description />
        </div>
        <div className="md:max-w-[35%] w-full flex flex-col justify-center  gap-1 mt-4">
          <span className="font-bold text-gray-400">REQUEST (EXAMPLE)</span>
          <Request codeSnippet={codeSnippet} />
          <span className="font-bold text-gray-400">
            RESPONSE 200 (EXAMPLE)
          </span>
          <Request codeSnippet={codeSnippet2} />
          <span className="font-bold text-gray-400">
            RESPONSE 401 (EXAMPLE)
          </span>
          <Request codeSnippet={codeSnippet3} />
          <span className="font-bold text-gray-400">
            RESPONSE 401 INVALID EMAIL(EXAMPLE)
          </span>
          <Request codeSnippet={codeSnippet4} />
          <span className="font-bold text-gray-400">
            RESPONSE 401 INVALID API KEY(EXAMPLE)
          </span>
          <Request codeSnippet={codeSnippet5} />
        </div>
      </div>
    </div>
  );
}

export default App;
