import React from "react";

const Description = () => {
  const propertyDescriptions = {
    user_email: "Email address of user submitting a return request",
    employee_name: "The employee's name",
    employee_email:
      "Employee's email address where they can receive notifications",
    employee_phone:
      "Employee cell phone number if you'd like them to receive SMS notifications",
    employee_street1: "Employee Shipping Address Street 1",
    employee_street2: "Employee Shipping Address Street 2",
    employee_city: "Employee Shipping Address City",
    employee_state: "Employee Shipping Address State",
    employee_zip: "Employee Shipping Address Zip Code",
    fd_department: "Name of Person of Department receiving laptop return",
    fd_street_1: "Final Destination Shipping Address Street 1",
    fd_street_2: "Final Destination Shipping Address Street 2",
    fd_city: "Final Destination Shipping Address City",
    fd_state: "Final Destination Shipping Address State",
    fd_zip: "Final Destination Shipping Address Zip Code",
  };
  return (
    <div className="flex flex-col justify-start gap-2 p-4">
      <h2 className="text-[#FFB78E] font-semibold text-xl">GUIDE</h2>
      <h1 className="text-black text-4xl">Create Order</h1>
      <div className="mt-2 flex gap-2 items-center flex-row">
        <span className="p-1 rounded-lg bg-gray-600 text-white font-semibold">
          Post
        </span>
        <span>
          https://api.laptopreturn.com
          <span className="text-black text-xl font-semibold">/order</span>
        </span>
      </div>
      <span className="text-sky-500 text-2xl font-semibold mt-2 leading-10">
        Requirements
      </span>
      <div className="mt-2">
        {Object.keys(propertyDescriptions).map((key) => (
          <div
            key={key}
            className="mb-4 pl-5 flex flex-row justify-start items-center gap-5"
          >
            <label className="block text-lg font-semibold mb-1">{key}:</label>
            <p className="text-gray-700">{propertyDescriptions[key]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Description;
