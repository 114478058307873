import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
const Request = ({ codeSnippet }) => {
  return (
    <div>
      <pre>
        <SyntaxHighlighter
          className="rounded-lg"
          language="js"
          PreTag="div"
          children={codeSnippet}
        />
      </pre>
    </div>
  );
};

export default Request;
