import React from "react";

const Nav = () => {
  return (
    <div className="flex w-full flex-row justify-start items-center gap-8 h-16 max-w-full shadow-lg mx-auto px-1 md-px-4 text-white">
      <img
        src="/favicon.ico"
        alt="logo"
        className="md:w-[80px] md:h-[50px] w-[30px] h-[30px]"
      />
      <h1 className="md:text-3xl text-md font-normal text-black">
        Developers <span className="text-sky-500 font-semibold">Web API</span>
      </h1>
      <h3 className="text-blue-600 border-b-2 h-full md:pt-4 pt-5 border-blue-600 md:text-2xl text-sm font-semibold hover:text-black hover:border-black cursor-pointer">
        API Reference
      </h3>
    </div>
  );
};

export default Nav;
